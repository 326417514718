.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15rem;
	&__inner {
		text-align: center;
		font-size: 1.5rem;
	}
	&__text {
		position: relative;
		margin: 0;
		&::after {
			content: '...';
			position: absolute;
		}
	}
	&__spinner {
		border-radius: 50%;
		width: 5em;
		height: 5em;
		margin: 10px auto;
		font-size: 10px;
		position: relative;
		text-indent: -9999em;
		border: 0.6em solid #0b2644;
		border-left: 0.6em solid #f3d777;
		transform: translateZ(0);
		animation: loading 1.1s infinite linear;
		&::after {
			border-radius: 50%;
			width: 5em;
			height: 5em;
		}
	}
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
