.register-form {
	margin-bottom: 5rem;
	&__title {
		padding: 5rem 0;
		margin-bottom: 0.5rem;
	}
	&__split-box {
		display: flex;
		div {
			flex: 1 0 auto;
			max-width: calc(50% - 0.9375rem);
			&:first-of-type {
				margin-right: 0.9375rem;
			}
			&:last-of-type {
				margin-left: 0.9375rem;
			}
		}
	}
	&__list {
		display: flex;
		justify-content: space-between;
		padding: 0 1rem;
		margin-bottom: 0;
	}
	&__item {
		font-weight: 300;
		color: #0b2644;
		position: relative;
		line-height: 1.5;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		font-size: 1.5rem;
		padding: 0.4375rem 0.75rem 0.4375rem 4.0625rem;
		flex-grow: 1;
		margin-bottom: 2rem;
		cursor: default;
		&::before {
			content: '\2714';
			display: block;
			position: absolute;
			text-align: center;
			background: #f3d777;
			left: 0;
			top: 0;
			margin-top: 0;
			font-family: 'Glyphter';
			font-style: normal;
			font-weight: 400;
			width: 2.875rem;
			height: 2.875rem;
			border-radius: 1.09375rem 0 1.09375rem 0;
			line-height: 2.75rem;
		}
		&::after {
			content: attr(data-text);
			height: 0;
			visibility: hidden;
			overflow: hidden;
			user-select: none;
			pointer-events: none;
			font-weight: 700;
		}
		&:hover {
			font-weight: 700;
			&::before {
				background: #f7e3a0;
			}
		}
	}
	&__text {
		margin-top: 0;
		margin-bottom: 1rem;
		line-height: 1.4;
		font-size: 1.25em;
		text-align: center;
		&--large {
			font-size: 1.9375rem;
			margin-bottom: 4rem;
			font-weight: 300;
			line-height: 1.2;
		}
	}
	&__second {
		padding-top: 82px;
	}
	&__go-back {
		background: transparent;
		border: none;
		cursor: pointer;
		text-align: center;
		font-size: 1.25rem;
		text-decoration: none;
		color: #0b2644;
		&:hover {
			font-weight: 700;
		}
	}
	.small-padding {
		padding-bottom: 0;
		margin-bottom: 3.75rem;
	}

	.no-padding {
		padding: 0;
	}

	@media (max-width: 1023px) {
		&__title {
			padding-top: 3.125rem;
			padding-bottom: 3.125rem;
		}
		&__list {
			display: flex;
			justify-content: space-between;
			padding: 0 1rem;
			margin-bottom: 0;
		}
		&__item {
			font-weight: 300;
			color: #0b2644;
			position: relative;
			line-height: 1.5rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			font-size: 1.125rem;
			padding: 0.1875rem 0.75rem 0.1875rem 2.5rem;
			flex-grow: 1;
			margin-bottom: 2rem;
			cursor: default;
			&::before {
				left: 0;
				top: 0;
				margin-top: 0;
				width: 1.875rem;
				height: 1.875rem;
				border-radius: 0.72917rem 0 0.72917rem 0;
				line-height: 1.875rem;
			}
		}
	}

	@media (max-width: 699px) {
		&__title {
			padding-top: 2.75rem;
			padding-bottom: 2.75rem;
			font-size: 1.875rem;
			line-height: 1.2;
		}
		&__text {
			font-size: 1rem;
			&--large {
				font-size: 1.1875rem;
				line-height: 1.2;
				margin-bottom: 2rem;
			}
		}
		&__list {
			flex-direction: column;
			padding: 0;
		}
		&__item {
			margin-bottom: 1rem;
		}
		&__split-box {
			display: flex;
			flex-direction: column;
			div {
				flex: 1 0 auto;
				max-width: 100%;
				&:first-of-type {
					margin-right: 0;
				}
				&:last-of-type {
					margin-left: 0;
				}
			}
		}
		&__go-back {
			font-size: 1rem;
			line-height: 1.5;
		}
		&__second {
			padding-top: 60px;
		}

		.small-padding {
			margin-bottom: 1.75rem;
		}
	}
}
