.transactions {
	margin-bottom: 5rem;
	&__title {
		padding: 5rem 0;
		margin-bottom: 0.5rem;
	}
	&__payments {
		background-color: #f9f5e8;
		font-size: 1.1875rem;
		line-height: 1.2;
		padding: 1rem;
		color: #0b2644;
		margin-bottom: 3rem;
		display: flex;
	}
	&__payments-qr {
		width: 140px;
		height: 140px;
		margin: 1.375rem;
		img {
			width: 140px;
			height: 140px;
		}
	}
	&__payments-info {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 0.9375rem;
	}
	&__payments-item {
		display: flex;
		p {
			width: 50%;
			padding: 0.4375rem;
			margin: 0;
			&:first-of-type {
				text-align: right;
			}
		}
	}
	&__refund {
		padding-top: 2rem;
		text-align: center;
		font-size: 1.1875rem;
		line-height: 1.2;
		margin-bottom: 0;
		span {
			text-decoration: underline;
			font-weight: bold;
			cursor: pointer;
		}
	}

	@media (max-width: 1023px) {
		&__title {
			padding-top: 3.125rem;
			padding-bottom: 3.125rem;
		}
	}

	@media (max-width: 699px) {
		&__title {
			padding-top: 2.75rem;
			padding-bottom: 2.75rem;
			font-size: 1.875rem;
			line-height: 1.2;
		}
		&__payments {
			flex-direction: column;
		}
		&__payments-qr {
			width: 100%;
			display: flex;
			justify-content: center;
			margin: 1.375rem 0;
		}
		&__payments-info {
			padding: 0.9375rem 0;
		}
	}
}
