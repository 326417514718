.about-us {
	background-color: #f1f4f7;
	padding-top: 5rem;
	padding-bottom: 3.125rem;
	&__info {
		display: flex;
		margin-top: 4rem;
	}
	&__photo {
		margin-bottom: 2rem;
		border-radius: 3.125rem 0;
		margin: 0 0 1em;
		width: 200px;
		height: 200px;
	}
	&__text {
		margin-bottom: 2rem;
		margin-left: 2em;
		h3 {
			font-size: 1.25em;
			margin-bottom: 1rem;
			margin-top: 0;
			line-height: 1.2;
			&::before {
				display: block;
				content: '';
				max-width: 3.5625rem;
				height: 0;
				margin: 0 0 0.5rem;
				border-bottom: 1px solid #f3d777;
			}
		}
		p {
			font-size: 1.25em;
			margin-bottom: 1rem;
			line-height: 1.4;
			margin-top: 0;
		}
		ul {
			margin-top: 2rem;
			margin-left: 1.25rem;
			font-size: 1.25em;
			padding: 0;
			line-height: 1.4;
			margin-bottom: 1rem;
		}
		li {
			margin-bottom: 0.3125rem;
		}
	}
	&__contact {
		font-weight: 300;
		font-size: 24px;
		margin-top: 0;
		line-height: 1.4;
		margin-bottom: 1rem;
	}

	@media (max-width: 1023px) {
		padding-top: 3.125rem;
		padding-bottom: 1.25rem;
	}
	@media (max-width: 699px) {
		padding-top: 2.75rem;
		padding-bottom: 1.5rem;
		h2 {
			font-size: 1.875rem;
		}
		&__text {
			h3,
			p,
			li {
				font-size: 1rem;
			}
		}
	}
	@media (max-width: 599px) {
		&__info {
			flex-direction: column;
		}
		&__photo {
			margin-bottom: 2rem;
			width: 100%;
			text-align: center;
		}
		&__text {
			margin-left: 0;
		}
	}
}
