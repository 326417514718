.modal {
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1000;
		background-color: rgba(0, 0, 0, 0.55);
	}
	&__block {
		width: 600px;
		max-width: 71.25rem;
		z-index: 1001;
		padding: 2rem;
		border: none;
		border-radius: 0;
		background-color: #fff;
		position: relative;
		top: 140px;
		margin-right: auto;
		margin-left: auto;
	}
	&__title {
		margin-bottom: 1rem;
		font-size: 1.9375rem;
		line-height: 1.2;
		margin-top: 0;
		text-align: left;
	}
	&__form {
		margin-top: 2rem;
	}
	&__close {
		font-size: 4rem;
		top: 1rem;
		right: 2rem;
		line-height: 1;
		position: absolute;
		z-index: 10;
		color: #153150;
		cursor: pointer;
		appearance: none;
		border-radius: 2.1875rem 0;
		outline: none;
		border: none;
		background: none;
		margin: 0;
		padding: 0;
	}

	@media (max-width: 699px) {
		&__block {
			width: 100%;
			top: 50%;
			transform: translateY(-50%);
		}
		&__title {
			padding-right: 30px;
		}
	}
}
