.header {
	background-color: #0b2644;
	color: #f4efde;
	padding: 1.6875rem 0;
	&__wrap {
		display: flex;
		flex-wrap: wrap;
	}
	&__logo {
		width: 7.8125rem;
		height: 4.5rem;
		text-decoration: none;
		color: #f4efde;
		display: block;
		background: url(../../assets/icons/logo_fildy.svg) no-repeat;
		background-size: contain;
		text-indent: -9999px;
		overflow: hidden;
	}
	&__item {
		&--left {
			flex: 1 1 auto;
			margin-right: auto;
			max-width: 100%;
		}
		&--right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex: 0 1 auto;
			margin-left: auto;
			max-width: 100%;
		}
	}
	&__list {
		padding: 0;
		margin: 0;
		list-style: none;
		position: relative;
		display: flex;
		counter-reset: a;

		li {
			a::before {
				counter-increment: a;
				content: counter(a);
			}
		}
	}
	&__link {
		margin: 0.3125rem 1.75rem 0.3125rem 0;
		float: left;
		&:last-child {
			margin-right: 0;
		}
	}
	&__link-menu {
		display: none;
		text-align: center;
	}
	&__anchor {
		text-decoration: none;
		color: #f4efde;
		padding: 1.25rem 1.5rem;
		padding-left: 4rem;
		font-size: 1.1875rem;
		min-height: 2rem;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		position: relative;
		line-height: 1;
		&::before {
			font-size: 1.75rem;
			width: 3.1875rem;
			height: 3.1875rem;
			line-height: 3.1875rem;
			margin-top: -1.625rem;
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 0;
			text-align: center;
			border-radius: 50%;
			font-weight: 700;
			background: #153150;
		}
		&::after {
			content: attr(data-text);
			content: attr(data-text) / '';
			height: 0;
			visibility: hidden;
			overflow: hidden;
			user-select: none;
			pointer-events: none;
			font-weight: 700;
		}
		&:hover,
		&.is-active {
			font-weight: 700;
			&::before {
				background: #f3d777;
				color: #0b2644;
			}
		}

		&--icon {
			&::before {
				content: '' !important;
			}

			.header__icon {
				height: 25px;
				width: 25px;
				position: absolute;
				&--users {
					height: 30px;
					width: 30px;
					left: 10px;
					top: 12px;
				}
				&--profile {
					left: 13px;
					top: 15px;
				}
				&--logout {
					left: 16px;
					top: 17px;
				}
				svg {
					fill: #f4efde;
				}
			}

			&:hover,
			&.is-active {
				.header__icon {
					svg {
						fill: #153150;
					}
				}
			}
		}
	}

	@media (max-width: 1199px) {
		&__link {
			margin-right: 1.125rem;
		}
	}

	@media (max-width: 1023px) {
		&__link {
			margin-right: 0;
		}
		&__anchor {
			font-size: 1rem;
			padding-left: 3.25rem;
			&::before {
				font-size: 1.25rem;
				width: 2.5rem;
				height: 2.5rem;
				margin-top: -1.1875rem;
				line-height: 2.5rem;
			}

			&--icon {
				.header__icon {
					height: 16px;
					width: 16px;
					&--users {
						height: 20px;
						width: 20px;
						left: 10px;
						top: 18px;
					}
					&--profile {
						height: 16px;
						width: 16px;
						left: 12px;
						top: 19px;
					}
					&--logout {
						width: 17px;
						left: 13px;
						top: 21px;
					}
				}
			}
		}
	}
	@media (max-width: 790px) {
		padding: 0.8rem 0;
		&__item {
			&--left {
				display: flex;
				align-items: center;
			}
		}
		&__logo {
			width: 5.1875rem;
			height: 3rem;
		}
	}
	@media (max-width: 699px) {
		padding: 0.8rem 0;
		&__wrap {
			display: flex;
			flex-direction: column;
		}
		&__link-mobile {
			display: none;
			&--toggle {
				text-align: center;
			}
			&.is-active {
				display: block;
			}
		}
		&__item {
			&--left {
				display: block;
				margin: 0 auto;
			}
			&--right {
				justify-content: center;
				margin-left: 0;
				padding-top: 10px;
			}
		}
		&__logo {
			display: block;
			margin: 0 auto;
		}
		&__list {
			flex-direction: column;
		}
	}
}

.nav {
	&--mobile {
		display: none;
		visibility: hidden;
		opacity: 0;
	}
	@media (max-width: 699px) {
		&--desktop {
			display: none;
			visibility: hidden;
			opacity: 0;
		}
		&--mobile {
			display: block;
			visibility: visible;
			opacity: 1;
		}
	}
}
