* {
	box-sizing: border-box;
	font-size: 100%;
}

html,
body,
#root {
	height: 100%;
}

body > #root > footer {
	position: sticky;
	top: 100vh;
}

body {
	font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
	font-weight: 400;
	line-height: 1.5;
	color: #0b2644;
}

.row-main {
	margin: auto;
	padding: 0 1rem;
	max-width: 71.25rem;
	&--wider {
		max-width: 75rem;
	}
	&--thiner {
		max-width: 61.25rem;
	}
	&--thinest {
		max-width: 39.5rem;
	}
}

h1 {
	margin-bottom: 3.75rem;
	text-align: center;
	font-size: 2.5rem;
	margin-top: 0;
	line-height: 1.2;
}

h2 {
	text-align: center;
	font-size: 2.5rem;
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 0.5rem;
}

.overflow-element {
	overflow-x: auto;
}

.is-clickable {
	text-decoration: underline;
	cursor: pointer;
}
