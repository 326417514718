.users {
	margin-bottom: 5rem;
	&__title {
		padding: 5rem 0;
		margin-bottom: 0.5rem;
	}
	&__table {
		position: relative;
		text-align: left;
		font-size: 1.1875rem;
		line-height: 1.2;
		width: 100%;
		border-collapse: collapse;
		border-radius: 2.1875rem;
		border-left: none;
		border-right: none;
		thead {
			background: #f1f4f7;
			color: #0b2644;
			border: 1px solid #f1f4f7;
			th {
				margin: 0;
				padding: 0.75rem;
				font-weight: 700;
				text-align: left;
			}
		}
		tbody {
			border: 1px solid #f1f4f7;
			background-color: #fff;
			border-left: none;
			border-right: none;
			tr {
				border-bottom: 1px solid #f1f4f7;
				background-color: #fff;
				&:hover {
					text-decoration: underline;
					cursor: pointer;
				}
			}
			td {
				padding: 0.75rem;
				&:first-of-type {
					img {
						display: block;
						margin: auto;
					}
					&::before {
						content: '';
						padding-top: 114px;
					}
				}
			}
		}
		&--amount,
		&--long {
			white-space: nowrap;
		}
	}

	@media (max-width: 1023px) {
		&__title {
			padding-top: 3.125rem;
			padding-bottom: 3.125rem;
		}
	}

	@media (max-width: 699px) {
		&__title {
			padding-top: 2.75rem;
			padding-bottom: 2.75rem;
			font-size: 1.875rem;
			line-height: 1.2;
		}
	}
}
