.transactions-table {
	text-align: left;
	font-size: 1.1875rem;
	line-height: 1.2;
	width: 100%;
	border-collapse: collapse;
	border-radius: 2.1875rem;
	border-left: none;
	border-right: none;
	&__head {
		background: #f1f4f7;
		color: #0b2644;
		border: 1px solid #f1f4f7;
		th {
			margin: 0;
			padding: 0.75rem;
			font-weight: 700;
			text-align: left;
		}
	}
	&__body {
		border: 1px solid #f1f4f7;
		background-color: #fff;
		border-left: none;
		border-right: none;
		tr {
			border-bottom: 1px solid #f1f4f7;
			background-color: #fff;
		}
		td {
			padding: 0.75rem;
		}
	}
	&__foot {
		background: #f1f4f7;
		color: #0b2644;
		border: 1px solid #f1f4f7;
		td {
			margin: 0;
			padding: 0.75rem;
			font-weight: 700;
			text-align: center;
		}
	}
	&__date,
	&__amount {
		white-space: nowrap;
	}
	.negative {
		color: red;
		position: relative;
		&::before {
			content: '-';
			position: absolute;
			left: 5px;
		}
	}
}
