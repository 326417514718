.profile {
	margin-bottom: 5rem;
	&__title {
		padding: 5rem 0;
		margin-bottom: 0.5rem;
	}

	@media (max-width: 1023px) {
		&__title {
			padding-top: 3.125rem;
			padding-bottom: 3.125rem;
		}
	}

	@media (max-width: 699px) {
		&__title {
			padding-top: 2.75rem;
			padding-bottom: 2.75rem;
			font-size: 1.875rem;
			line-height: 1.2;
		}
	}
}
