.alert {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;
	color: #fff;
	font-size: 1.3rem;
	font-weight: 400;
	text-align: center;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	padding: 1.3rem 8rem;
	box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25);
	&--success {
		background-color: #20bf6b;
	}
	&--error {
		background-color: #eb4d4b;
	}

	@media (max-width: 1023px) {
		width: 70%;
		padding: 2rem;
	}

	@media (max-width: 699px) {
		width: 90%;
		font-size: 1rem;
		padding: 1rem;
	}
}
