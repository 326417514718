.collection {
	margin-bottom: 3.125rem;
	&__title {
		padding-top: 5rem;
		margin-bottom: 0.5rem;
	}
	&__text {
		font-weight: 300;
		font-size: 175%;
		line-height: 1.6;
		text-align: center;
		margin-top: 0;
		margin-bottom: 4rem;
	}
	&__savings {
		display: inline-block;
		margin: 0 0.3125rem;
		padding: 0 2.1875rem 0 0.4375rem;
		position: relative;
		font-size: 1.75rem;
		font-weight: 700;
		background: #f9f5e8;
		cursor: pointer;
		&::before {
			content: url('../../assets/icons/pen.svg');
			position: absolute;
			right: 0.3125rem;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.25rem;
			width: 20px;
			height: 26px;
			display: inline-block;
		}
	}

	@media (max-width: 1023px) {
		&__title {
			padding-top: 3.125rem;
		}
	}

	@media (max-width: 699px) {
		&__title {
			padding-top: 2.75rem;
			font-size: 1.875rem;
			line-height: 1.2;
		}
	}
}
