.input-select {
	position: relative;
	width: 100%;
	height: 4rem;
	margin: 0 0 0.5rem;
	padding: 1rem;
	border: none;
	border-radius: 0;
	background-color: #f9f5e8;
	font-size: 1.375rem;
	line-height: 1.5;
	color: #0b2644;
}
