// Document
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

// Sections
body {
	margin: 0;
}

main {
	display: block;
}

// Text-level semantics
a {
	background-color: transparent;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}

b,
strong {
	font-weight: bold;
}
/**
* http://code.iamkate.com/html-and-css/fixing-browsers-broken-monospace-font-handling/
*/

pre,
code,
kbd,
samp {
	font-family: monospace;
	font-size: 1em;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	vertical-align: baseline;
	font-size: 75%;
	line-height: 0;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

// Embedded content
img {
	border-style: none;
}

// Forms
button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font-family: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

legend {
	display: table;
	max-width: 100%;
	padding: 0;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

textarea {
	overflow: auto;
}

[type='checkbox'],
[type='radio'] {
	padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	-webkit-appearance: textfield;
}

[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

// Interactive
details {
	display: block;
}

summary {
	display: list-item;
}

// Misc

template {
	display: none;
}

[hidden] {
	display: none;
}

a:focus {
	outline: 0;
}

pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

img,
iframe,
object,
embed,
svg {
	vertical-align: middle;
}

fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

figure {
	margin: 0;
}

[disabled] {
	cursor: default;
}
