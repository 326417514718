.user {
	margin-bottom: 5rem;
	&__title {
		padding: 5rem 0 1.5rem;
		margin-bottom: 0.5rem;
	}
	&__split-container {
		display: flex;
	}
	&__split-box {
		flex: 1 0 auto;
		text-align: center;
		width: 50%;
		p {
			font-size: 1.1875rem;
			line-height: 1.2;
		}
	}
	&__payment-info {
		margin-bottom: 2rem;
		> p {
			display: block;
			text-align: center;
			font-weight: bold;
			cursor: pointer;
			position: relative;
			&::after {
				content: '';
				position: absolute;
			}
		}
	}

	@media (max-width: 1023px) {
		&__title {
			padding-top: 3.125rem;
			padding-bottom: 3.125rem;
		}
	}

	@media (max-width: 699px) {
		&__title {
			padding-top: 2.75rem;
			padding-bottom: 2.75rem;
			font-size: 1.875rem;
			line-height: 1.2;
		}
		&__split-box {
			width: 100%;
		}
		&__split-container {
			flex-direction: column;
		}
	}
}
