.input-textarea {
	position: relative;
	overflow: hidden;

	&__field {
		height: 9.0625rem;
		resize: none;

		display: block;
		box-sizing: border-box;
		width: 100%;
		margin: 0 0 2rem;
		padding: 1rem;
		border: none;
		border-radius: 0;
		background-color: #f9f5e8;
		box-shadow: none;
		font-family: inherit;
		font-size: 1.375rem;
		font-weight: 300;
		line-height: 1.5;
		color: #0b2644;
		transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
		appearance: none;
		outline: none;
		&::placeholder {
			color: #0b2644;
		}
		&.is-active {
			padding-top: 1.5rem;
			padding-bottom: 0.5rem;
		}
	}

	&__label {
		transition: all 0.3s;
		transform: translateY(-100%);
		position: absolute;
		padding: 0 1rem;
		font-weight: 700;
		color: #0b2644;
		line-height: 1.5;
		font-size: 1rem;
		&.is-active {
			transform: translateY(7px);
		}
	}

	@media (max-width: 699px) {
		&__field {
			font-size: 1.125rem;
		}
	}
}
