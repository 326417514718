.input-input-with-unit {
	position: relative;
	overflow: hidden;
	display: flex;
	&__field {
		display: block;
		box-sizing: border-box;
		width: 80%;
		height: 4rem;
		margin: 0 0 2rem;
		padding: 1rem;
		border: none;
		border-radius: 0;
		background-color: #f9f5e8;
		box-shadow: none;
		font-family: inherit;
		font-size: 1.375rem;
		font-weight: 300;
		line-height: 1.5;
		color: #0b2644;
		transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
		appearance: text;
		outline: none;
		flex: 1 1 0px;
		&::placeholder {
			color: #0b2644;
		}
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		&.is-active {
			padding-top: 1.5rem;
			padding-bottom: 0.5rem;
		}
	}

	&__label {
		transition: all 0.3s;
		transform: translateY(-100%);
		position: absolute;
		padding: 0 1rem;
		font-weight: 700;
		color: #0b2644;
		line-height: 1.5;
		font-size: 1rem;
		&.is-active {
			transform: translateY(7px);
		}
	}

	&__unit {
		padding: 0 1rem;
		border: none;
		background: #f9f5e8;
		color: #0b2644;
		text-align: center;
		white-space: nowrap;
		display: -ms-flexbox;
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		font-size: 1.375rem;
		font-weight: 300;
		height: 4rem;
	}

	@media (max-width: 699px) {
		&__field,
		&__unit {
			font-size: 1.125rem;
		}
	}
}
