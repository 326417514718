.collection-table {
	position: relative;
	text-align: left;
	font-size: 1.1875rem;
	line-height: 1.2;
	width: 100%;
	border-collapse: collapse;
	border-radius: 2.1875rem;
	border-left: none;
	border-right: none;
	&__head {
		background: #f1f4f7;
		color: #0b2644;
		border: 1px solid #f1f4f7;
		th {
			margin: 0;
			padding: 0.75rem;
			font-weight: 700;
			text-align: left;
		}
	}
	&__body {
		border: 1px solid #f1f4f7;
		background-color: #fff;
		border-left: none;
		border-right: none;
		tr {
			border-bottom: 1px solid #f1f4f7;
			background-color: #fff;
		}
		td {
			padding: 0.75rem;
			&:last-of-type {
				text-align: center;
			}
			input {
				width: 100%;
			}
		}
	}
	&__add {
		td {
			input {
				width: 100%;
			}
			&:last-of-type {
				text-align: center;
			}
		}
	}
	&__order {
		max-width: 5%;
	}
	&__image {
		width: 10%;
	}
	&__name {
		width: 20%;
	}
	&__assigned {
		width: 10%;
	}
	&__day-of-buy {
		width: 15%;
	}
	&__delivery {
		width: 10%;
	}
	&__price {
		width: 15%;
		white-space: nowrap;
	}
	&__settings {
		width: 15%;
	}
	&__empty {
		text-align: center;
	}
	&__hidden {
		display: none;
	}
	&__image-box {
		img {
			max-height: 100px;
		}
	}

	&__loading {
		position: relative;
		&.is-loading {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(255, 255, 255, 0.6);
				backdrop-filter: blur(2px);
				z-index: 200;
			}
			.spinner,
			.spinner:after {
				border-radius: 50%;
				width: 4em;
				height: 4em;
				z-index: 201;
			}
			.spinner {
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 9px;
				text-indent: -9999em;
				border: 0.6em solid #0b2644;
				border-left: 0.6em solid #f3d777;
				transform: translateZ(0);
				animation: load8 1.1s infinite linear;
			}
		}

		.spinner {
			display: none;
		}
	}

	button {
		appearance: none;
		cursor: pointer;
		border: none;
		background-color: white;
		text-decoration: underline;
	}
	input[type='file'] {
		background-color: white;
		&::-webkit-file-upload-button {
			display: none;
		}
	}
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
