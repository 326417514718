.input-button {
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1.2;
	background-color: #f3d777;
	color: #0b2644;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto 2rem auto;
	padding: 1.375rem 2.625rem;
	border: 1px solid transparent;
	border-radius: 2.1875rem 0 2.1875rem 0;
	transition: background-color 0.25s ease-out, color 0.25s ease-out;
	font-family: inherit;
	font-size: 1.875rem;
	text-align: center;
	cursor: pointer;
	outline: none;
	margin-top: 2rem;
	margin-bottom: 2rem;
	&::after {
		content: attr(data-text);
		content: attr(data-text) / '';
		height: 0;
		visibility: hidden;
		overflow: hidden;
		user-select: none;
		pointer-events: none;
	}
	&:hover {
		background-color: #f7e3a0;
	}

	@media (max-width: 699px) {
		padding: 0.9375rem 1.5625rem;
		font-size: 1.375rem;
	}
}

.u-text-center {
	text-align: center;
}

.button-loader,
.button-loader:after {
	border-radius: 50%;
	width: 4em;
	height: 4em;
	@media (max-width: 699px) {
		width: 2.9em;
		height: 2.9em;
	}
}
.button-loader {
	font-size: 9px;
	position: relative;
	text-indent: -9999em;
	border: 0.7em solid #0b26447a;
	border-left: 0.7em solid #0b2644;
	transform: translateZ(0);
	animation: load8 1.1s infinite linear;
	@media (max-width: 699px) {
		border: 0.5em solid #0b26447a;
		border-left: 0.5em solid #0b2644;
	}
}
@keyframes load8 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
