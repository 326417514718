.footer {
	background-color: #0b2644;
	color: #f4efde;
	padding: 2.625rem 0 2.25rem;
	&__wrap {
		display: flex;
		div {
			flex: 1 0 auto;
		}
	}
	&__logo-img {
		display: block;
		width: 9.5rem;
		height: 5.5rem;
	}
	&__nav-list {
		display: flex;
		justify-content: flex-end;
		list-style: none;
		margin: 0;
	}
	&__nav-item {
		a {
			display: block;
			line-height: 1;
			color: #f4efde;
			text-decoration: none;
			position: relative;
			padding: 0.5rem 1.5rem 0.5rem 3rem;
			&:hover {
				font-weight: bold;
			}
			&::before {
				display: inline-block;
				content: '';
				width: 0.4375rem;
				height: 0.4375rem;
				border-radius: 50%;
				background: #f4efde;
				margin-right: 0.625rem;
				position: absolute;
				top: 50%;
				left: 1.5rem;
				margin-top: -0.1875rem;
			}
		}
		&:last-of-type {
			a {
				padding-right: 0;
			}
		}
	}
	&__copyright {
		hr {
			margin: 0.625rem 0;
			margin-top: 2.5rem;
			clear: both;
			max-width: 3.5625rem;
			height: 0;
			border-top: 0;
			border-right: 0;
			border-bottom: 1px solid #f3d777;
			border-left: 0;
		}
		p {
			margin-top: 0;
			line-height: 1.4;
		}
	}
	&__socs {
		text-align: end;
		p {
			margin-top: 0;
			margin-bottom: 0.3125rem;
			line-height: 1.4;
		}
		a {
			color: #f4efde;
			text-decoration: none;
		}
	}
	&__socs-wrap {
		margin-top: 0.625rem;
	}
	&__socs-link {
		color: #f4efde;
		text-decoration: none;
		display: inline-block;
		padding: 0.6875rem;
		background: #1d3c5f;
		border-radius: 1.09375rem 0 1.09375rem 0;
		text-decoration: none;
		margin: 0 0.4375rem 0.4375rem 0;
		svg {
			display: block;
			width: 1.5rem;
			height: 1.5rem;
			fill: #fff;
		}
		&:hover {
			background: #f3d777;
		}
		&:last-of-type {
			margin-right: 0;
		}
	}
	@media (max-width: 1023px) {
		&__logo {
			img {
				width: 7.8125rem;
				height: 4.5rem;
			}
		}
	}
	@media (max-width: 699px) {
		&__wrap {
			flex-direction: column;
		}
		&__logo {
			margin-bottom: 2rem;
		}
		&__nav-wrap {
			margin-bottom: 2rem;
		}
		&__nav-list {
			justify-content: flex-start;
			padding-left: 0;
			flex-wrap: wrap;
		}
		&__nav-item {
			a {
				padding-left: 1.5rem;
				padding-right: 3rem;
				&::before {
					left: 0;
				}
			}
		}
		&__copyright {
			order: 2;
			p {
				margin-bottom: 0.3125rem;
			}
		}
		&__socs {
			order: 1;
			text-align: start;
		}
	}
}
